.user-table-header {
    text-align: center;
    font-family: 'Nutmeg-Regular';
    font-size: 14px !important;
    color: #000;
}

.user-row {
    font-size: 0.8rem;
    font-family: Nutmeg-Book;
}

.user-row td {
    vertical-align: middle !important;
}

.user-row .rounded-circle {
    margin: 0 auto;
}

.table-hover tbody tr:hover {
    background-color: #dedede38;
}

.users-subtitle {
    font-size: 1rem;
    color: #989898;
}

.user-form-card {
    border: solid 1px#cacaca;
    @include box-shadow(none);
}

.collaborators {
    color: #794fc2 !important;
}

.btn-collaborators {
    border: 1px solid #673ab7b8 !important;
}

.activities {
    color: #0071CE !important;
}

.btn-activities {
    border: 1px solid #0071CE !important;
}

.collaborator-nickname {
    color: #6c757d;
}
.module-box-header .search-bar.right-side-icon .form-control {
    height: 40px !important;
}

button.btn-user,
button.btn-user:hover {
    color: #ffffff;
    background-color: #00778a;
}

button.btn-user-disabled {
    background-color: #cecece;
}

.zmdi-more-vert.admin-users {
    color: #00778a;
}
