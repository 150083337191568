@font-face {
  font-family: 'icomoon';
  src:  url('../assets/fonts/icomoon/icomoon.eot?558iap');
  src:  url('../assets/fonts/icomoon/icomoon.eot?558iap#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon/icomoon.ttf?558iap') format('truetype'),
    url('../assets/fonts/icomoon/icomoon.woff?558iap') format('woff'),
    url('../assets/fonts/icomoon/icomoon.svg?558iap#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-SECADMON_Blog_de_colaboracion:before {
  content: "\e900";
}

.icon-SECADMON_blog_Privado:before {
  content: "\e901";
}

.icon-SECADMON_Grupos_de_trabajo:before {
  content: "\e902";
}

.icon-SECADMON_Perfil_cumpleaos:before {
  content: "\e903";
}

.icon-SECADMON_Perfil_Grupos:before {
  content: "\e904";
}

.icon-SECADMON_Perfil_Habilidades:before {
  content: "\e905";
}

.icon-SECADMON_Perfil_Organizacion:before {
  content: "\e906";
}

.icon-SECADMON_Blog-P_Permitir-comentarios:before {
  content: "\e907";
}

.icon-SECADMON_Blog-P_Anclar-publicacin:before {
  content: "\e908";
}

.icon-SECADMON_Blog-P_Bloquear-comentarios:before {
  content: "\e909";
}

.icon-SECADMON_Blog-P_Desanclar-publicacin:before {
  content: "\e90a";
}

.icon-SECADMON_Blog-P_Eliminar-publicacin:before {
  content: "\e90b";
}

.icon-SECADMON_Blog-P_Editar-publicacin:before {
  content: "\e90c";
}

.icon-SECADMON_Descargar:before {
  content: "\e90d";
}
.icon-SECADMON_Mis-trmites_En-proceso:before {
  content: "\e90e";
}

.icon-SEJ_Tramites_aclaraciones:before {
  content: "\e90f";
}
.icon-SEJ_Tramites_educacion-y-cultura:before {
  content: "\e910";
}
.icon-SEJ_Tramites_informatica .path1:before {
  content: "\e911";
}
.icon-SEJ_Tramites_informatica .path2:before {
  content: "\e912";
  margin-left: -1.12890625em;
}
.icon-SEJ_Tramites_informatica .path3:before {
  content: "\e913";
  margin-left: -1.12890625em;
}
.icon-SEJ_Tramites_informatica .path4:before {
  content: "\e914";
  margin-left: -1.12890625em;
}
.icon-SEJ_Tramites_personal:before {
  content: "\e915";
}
.icon-SEJ_Tramites_ejercicio-presupuestal:before {
  content: "\e916";
}

.icon-SEJ_Mi_expediente:before {
  content: "\e917";
}

.icon-SEJ_Reportes:before {
  content: "\e918";
}

.icon-SEJ_Tramites:before {
  content: "\e919";
}

.icon-SEJ_Inicio:before {
  content: "\e91a";
}

.icon-SEJ_Tramites_juridico:before {
  content: "\e91b";
}

.icon-SEJ_Tramites_nomina:before {
  content: "\e91c";
}

.icon-SEJ_Tramites_recursos-materiales:before {
  content: "\e91d";
}

.icon-SEJ_Tramites_servicios-generales:before {
  content: "\e91e";
}

.icon-SEJ_Menu-tramites_Mensaje:before {
  content: "\e91f";
}
