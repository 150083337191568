.blog-main-title {
  font-size: 1.4em;
  font-family: Nutmeg-Bold;
  vertical-align: middle;
  color: #606469;
}

.blog-label-dark {
  color: #585c60;
}

.label-bottom {
  font-family: "Nutmeg-Bold";
  vertical-align: bottom;
}

.blog-title {
  color: #989898;
  font-family: Nutmeg-Bold;
  font-size: 1rem;
}

.post-content {
  font-family: Nutmeg-Book;
  font-weight: normal;
  font-size: 14px;
  color: #495057 !important;
  line-height: 1.6;
}

.post-title {
  color: #585c60;
  font-family: Nutmeg-Bold;
  font-size: 15px;
}

.button-section {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
}

@media all and (max-width: 1500px) and (min-width: 100px) {
  .button-section {
    margin-left: 0 !important;
    .button-section-check{
      padding-left: 0;
    }
  }
}
