.postItem__actionsMenu__option {
  span {
    color: #606469;
  }
}

.postItem__actionsMenu__option:hover {
  background-color: #FDEAED !important;

  i,
  span {
    color: #E93251;
  }

  &.PrivateBlog {
    background-color: #f0d3e3 !important;

    i,
    span {
      color: #b32572;
    }
  }
}

.br-word {
  word-break: break-word;
}

.nav-text.customColor {
  color: #606469;
}

li.open > a {
  background-color: #fff !important;
  color: #a1a1a1 !important;
}

li > .sidenav__workGroupsOption:hover,
li > .sidenav__workGroupsOption.active {
  background-color: #E7F5F5 !important;
  color: #18A19D !important;

  span {
    color: #18A19D !important;
  }
}

li > .sidenav__profileOption:hover,
li > .sidenav__profileOption.active {
  background-color: #F9E5ED !important;
  color: #C60B4C !important;

  span {
    color: #C60B4C !important;
  }
}

li > .sidenav__proceduresOption:hover,
li > .sidenav__proceduresOption.active {
  background-color: #f2f8e8 !important;
  color: #7fb91e !important;

  span {
    color: #7fb91e !important;
  }
}

li.sidenav__proceduresMulti {
  button, button::before {
    background-color: #fff !important;
    color: #a1a1a1 !important;
  }
  
  &.open, &:hover, &.selected {
    button, button::before {
      background-color: #f2f8e8 !important;
      color: #7fb91e !important;
      // border-bottom-right-radius: 0 !important;
    }

    span {
      color: #7fb91e !important;
    }
  }


  .prepend-icon {
    background-color: #f2f8e8 !important;
    
    span {
      color: #606469 !important;
    }

    &::after {
      color: #f2f8e8 !important;
    }

    &:hover {
      background-color: #f2f8e8 !important;

      span {
        color: #7fb91e !important;
      }
    }
  }

  ul.sub-menu {
    background-color: #f2f8e8 !important;
    color: #7fb91e !important;
  }
}

li > .sidenav__collaborationBlogOption:hover,
li > .sidenav__collaborationBlogOption.active {
  background-color: #FDEAED !important;
  color: #e93251 !important;

  span {
    color: #e93251 !important;
  }
}

li > .sidenav__collaboratorsOption:hover,
li > .sidenav__collaboratorsOption.active {
  background-color: #f2eef9 !important;
  color: #794fc2 !important;

  span {
    color: #794fc2 !important;
  }
}

li > .sidenav__privateBlogOption:hover,
li > .sidenav__privateBlogOption.active {
  background-color: #f0d3e3 !important;
  color: #b32572 !important;

  span {
    color: #b32572 !important;
  }
}

li > .sidenav__usersOption:hover,
li > .sidenav__usersOption.active {
  background-color: #e5f1f3 !important;
  color: #00778a !important;

  span {
    color: #00778a !important;
  }
}

.collapseButton {
  padding: 0px !important;
  box-shadow: none !important;
}

.btn-box-shadow {
  box-shadow: 0px 2px 5px 0px #cc6342 !important;
}

.textShadow {
  text-shadow: 2px 2px 5px #ef734c;
}

/**  **/
.icon-SECADMON_Grupos_de_trabajo {
  vertical-align: top;
}

.MuiButton-textPrimary:hover {
  background-color: #e9ecef3d;
}

.Line {
  border-bottom: 1px solid #e1e1e1 !important;
}

.MuiButton-label {
  font-family: "Nutmeg-Regular";
  font-size: 12px;
}

.MuiLinearProgress-bar {
  background-color: #1898bf !important;
}

.MuiLinearProgress-root {
  background-color: inherit !important;
  border-radius: 5px;
}

.card {
  border: solid 1px #cacaca;
  box-shadow: none;
}

.card-header {
  background-color: rgba(0, 0, 0, 0);
}

.app-login-header {
  color: #000000;
}

.zmdi-more-vert {
  color: #e93251;
}

.login-form button {
  box-shadow: none !important;
  border: solid 1px #e93251 !important;
  color: #e93251 !important;
  font-family: "Nutmeg-Regular" !important;
  font-size: 13px !important;
  margin-bottom: 7rem !important;
  border-radius: 9px !important;
  div {
    border-radius: 9px !important;
  }
}

.zmdi-notifications-none {
  color: #18a19d;
  font-weight: bold;
}

.user-detail .user-name {
  cursor: default;
  max-width: 300px;
  text-overflow: ellipsis;

  @media (max-width: 1000px) {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
  }

  @media (max-width: 700px) {
    max-width: 100px;
  }

  @media (max-width: 500px) {
    max-width: 50px;
  }
}

.private-blog {
  color: #b32572;
}

.SpaceBlogs {
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 977px) {
    flex-direction: row-reverse !important;
    width: 100% !important;

    .LinksSection {
      width: 35%;
    }

    .PostSpace {
      width: 65%;
    }
  }
}

.ProfileCustomIcons {
  color: #e93251;
  font-size: 25px;
}

.DatePickerCustom.MuiButton-outlinedPrimary {
  color: #b32572;
  border: 1px solid rgba(179, 37, 114, 0.5);

  &:hover {
    background-color: #f0d3e3;
    border: 1px solid rgba(179, 37, 114, 0.5);
  }

  span,
  i {
    color: #b32572;
  }
}

.CheckboxPrivateBlogCustom {
  color: #b32572 !important;
}

.CheckboxWorkGroupsCustom {
  color: #1171ce !important;
}

.ButtonPrivateBlogCustom.MuiButton-containedPrimary,
.ButtonPrivateBlogCustom.MuiButton-containedPrimary:hover {
  background-color: #b32572;
}

.EmojiButton {
  color: #e93251 !important;

  i {
    font-size: 18px;
  }

  &.EmojiButtonPrivateBlog {
    color: #b32572 !important;
  }
}

.text-privateBlog {
  color: #b32572 !important;
}

.PrivateBlogDatePicker:not(.Mui-disabled),
.PrivateBlogDatePicker:not(.Mui-disabled):hover {
  background-color: #b32572;
}

.blog-range-picker {
  .MuiPickersDay-root.Mui-selected {
    //background-color: #b32572 !important;
  }

  .MuiPickersDateRangeDay-rangeIntervalDayHighlight {
    background-color: #f0d3e3 !important;
  }
}

.private-blog-range-picker {
  .MuiPickersDay-root.Mui-selected {
    background-color: #b32572 !important;
  }

  .MuiPickersDateRangeDay-rangeIntervalDayHighlight {
    background-color: #f0d3e3 !important;
  }
}

.private-blog-button:enabled {
  background-color: #b32572 !important;
}

.procedures-footer {
  border-bottom: none !important;
}

.emoji-picker-react {
  @media only screen and (max-width: 445px) {
    width: unset !important;
  }
}

.button-pagination-number {
  font-size: 0.9rem !important;
}

.procedures-footer .button-pagination-number:disabled {
  color: #7FB91E;
}

.users-footer .button-pagination-number:disabled {
  color: #b32572;
}

.private-blog-footer .button-pagination-number:disabled {
  color: #b32572;
}

.general-blog-footer .button-pagination-number:disabled {
  color: #e93251;
}

// .scrollbar > div {
//   margin-right: 0 !important;
// }

.scrollbar-control {
  @media (max-width: 976px) {
    margin-right: 0 !important;
  }
}

.work-group-select > div > fieldset {
  border-color: #18A19D !important;
}

.work-group-select > .Mui-focused {
  color: #18A19D !important;
}

.contact-card-content{
  padding-left: 10px !important;
  padding-top: 10px !important;
}

.answersTitle{
  font-family: Nutmeg-Bold;
  // color: #606469;
  color: white;
}

.answersHeader{
  font-family: Nutmeg-Regular !important;
  color: "white" !important;
}