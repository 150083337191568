$fonts: (
  "Nutmeg-Black",
  "Nutmeg-BlackItalic",
  "Nutmeg-Bold",
  "Nutmeg-BoldItalic",
  "Nutmeg-Book",
  "Nutmeg-BookItalic",
  "Nutmeg-ExtraBold",
  "Nutmeg-ExtraBoldItalic",
  "NutmegHeadline-Black",
  "NutmegHeadline-BlackItalic",
  "NutmegHeadline-Bold",
  "NutmegHeadline-BoldItalic",
  "NutmegHeadline-Book",
  "NutmegHeadline-BookItalic",
  "NutmegHeadline-ExtBdIta",
  "NutmegHeadline-ExtraBold",
  "NutmegHeadline-Light",
  "NutmegHeadline-LightItalic",
  "NutmegHeadline-Regular",
  "NutmegHeadline-RegularItalic",
  "NutmegHeadline-Thin",
  "NutmegHeadline-ThinItalic",
  "NutmegHeadline-UltBlkIta",
  "NutmegHeadline-UltLtIta",
  "NutmegHeadline-UltraBlack",
  "NutmegHeadline-UltraLight",
  "Nutmeg-Light",
  "Nutmeg-LightItalic",
  "Nutmeg-Regular",
  "Nutmeg-RegularItalic",
  "Nutmeg-Thin",
  "Nutmeg-ThinItalic",
  "Nutmeg-UltraBlack",
  "Nutmeg-UltraBlackItalic",
  "Nutmeg-UltraLight",
  "Nutmeg-UltraLightItalic",
);

@each $font in $fonts {
  @font-face {
    font-family: $font;
    src: url("../../assets/fonts/Nutmeg/"+$font+".ttf") format('truetype');
  }
}